'use client'
import Image from "next/image";
import { Inter } from "next/font/google";
import MainLayout from "@/components/Layouts/Main";
import ContactForm from "@/components/Sections/ContactForm/ContactForm";
import HomeSection from "@/components/Sections/HomeSection/HomeSection";
import PricingSection from "@/components/Sections/PricingSection/PricingSection";
import ClientList from "@/components/Sections/ClientList/ClientList";
import CTASection from "@/components/Sections/CTASections/CTASection";
import PartnerList from "@/components/Sections/PartnerList/PartnerList";
import { useEffect, useRef, useState } from "react";
import Testimoni from "@/components/Sections/Testimoni/Testimoni";
import Services from "@/components/Sections/Services/Services";
import { home } from "@/helpers/content/id/home";
import MiniCard from "@/components/MiniCard/MiniCard";
import MiniCardStackThree from "@/components/MiniCardStackThree/MiniCardStackThree";
const inter = Inter({ subsets: ["latin"] });
import logo from "@/assets/lo.svg"
import { montserratBold, montserratMedium, montserratSemibold } from "@/assets/fonts/montserrat";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import { useTranslation } from 'react-i18next';

export default function Error404() {
  const [isShowBar, setisShowBar] = useState(false)
  const homeRef = useRef()
  const pricingRef = useRef()
  const servicesRef = useRef()
  const servicesMobileRef = useRef([])
  const servicesMainMobileRef = useRef()
  const contactRef = useRef()
  const clientRef = useRef()
  const [nextTab, setnextTab] = useState(0)
  const [nextTabMobile, setnextTabMobile] = useState(0)
  const [servicesElementScroll, setservicesElementScroll] = useState(0)
  const { t } = useTranslation();

  useEffect(() => {
    const getLocalStorage = JSON.parse(localStorage.getItem("type"))
    if (getLocalStorage) {
      console.log("local storage",getLocalStorage)
      if (getLocalStorage.index) {
        if (getLocalStorage.scrollTo == "services") {
          setnextTab(getLocalStorage.index)
        }else if(getLocalStorage.scrollTo == "services-mobile"){
          setnextTabMobile(getLocalStorage.index)
        }
        moveToSection(getLocalStorage.scrollTo, getLocalStorage.index)
      }else{
        moveToSection(getLocalStorage.scrollTo)
      }
    }
    localStorage.removeItem("type")
  }, [])
  

  const moveToSection = (type, index = 0) => {
    if (type == "pricingRef") {
      window.scrollTo({top: pricingRef.current.offsetTop, behavior:"smooth" })
    }else if(type == "client"){
      setisShowBar(false)
      window.scrollTo({
        top: clientRef.current.offsetTop, 
        behavior:"smooth"
      })
    }else if (type == "services") {
      setisShowBar(false)
      // setnextTab(index)
      window.scrollTo({
        top: servicesRef.current.offsetTop, 
        behavior:"smooth"
      })
    }
    else if (type == "services-mobile") {
      console.log(index)
      setservicesElementScroll(prev => prev > 0 ? prev : servicesMainMobileRef.current.getBoundingClientRect().top - 100)
      setisShowBar(false)
      setnextTabMobile(index)
      // setTimeout(() => {
      //   window.scrollTo({
      //     top: servicesMainMobileRef.current.getBoundingClientRect().top,
      //     bottom: servicesMainMobileRef.current.getBoundingClientRect().bottom,
      //     behavior:"smooth"
      //   })
      // }, 100);
    }
    else if(type == "article"){
      window.open("https://blog.ledgerowl.com/","_blank")
    }else if(type == "home") { 
      window.scrollTo({
        top: homeRef.current.offsetTop,
        behavior:"smooth"
      })
    }else if(type == "contact") { 
      window.scrollTo({
        top: contactRef.current.offsetTop,
        behavior:"smooth"
      })
    }else if(type == "consultation"){
      window.scrollTo({
        top: contactRef.current.offsetTop,
        behavior:"smooth"
      })
      // window.open("https://api.whatsapp.com/send?phone=6281287889023&text=%20%20Hi!%20Can%20I%20ask%20more%20on%20ledgerowl%20services%20%3f","_blank")
    }
  }

  useEffect(() => {
    // window.scrollTo({
    //   top: servicesElementScroll,
    //   behavior:"smooth"
    // })
  }, [servicesElementScroll])
  return (
    <MainLayout 
      setnextTab={setnextTab}
      setnextTabMobile={setnextTabMobile}
      setisShowBar={setisShowBar}
      isShowBar={isShowBar}
      moveToSection={moveToSection}
    >
      <div className="bg-blue-primary-25 w-full h-auto max-h-[1280px] flex justify-center items-center m-auto">
          <div className="w-full p-[100px] flex flex-col justify-center items-center mx-auto">
            <div className="pb-6"><Image src={logo} alt="lo"/></div>
            <div className="flex flex-col text-center justify-center space-y-4 items-center">
              <p className={`text-semantic-negative-500 ${montserratSemibold.className} text-sm`}>404</p>
              <p className={`text-gray-cool-700 ${montserratSemibold.className} text-[24px]`}>{t("page_404_title")}</p>
              <p className={`text-gray-cool-400 ${montserratMedium.className} text-sm`}>{t("page_404_desc")}</p>
            </div>
            <Link href={"/"} className="py-3 mt-6 hover:cursor-pointer hover:bg-blue-primary-600 w-fit rounded-[8px] items-center flex px-6 bg-blue-primary-500 text-base-white">
              <p className={`${montserratSemibold.className}`}>{t("page_404_back")}</p>
              <ArrowRightIcon className="w-[16px] ml-[14px]"/>
            </Link>
          </div>
      </div>
    </MainLayout>
  )
}
